// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, GithubAuthProvider } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBzm-gSvmQ-fje3ErviZuNWNkcCmNX7lII",
    authDomain: "advmfg-test.firebaseapp.com",
    projectId: "advmfg-test",
    storageBucket: "advmfg-test.appspot.com",
    messagingSenderId: "111457069180",
    appId: "1:111457069180:web:a2db8a4b13b3e8f08dc1f9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
export const gitHubProvider = new GithubAuthProvider();
export default app;