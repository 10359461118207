import React from "react";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import ConstructionIcon from '@mui/icons-material/Construction';
import Avatar from '@mui/material/Avatar';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import MessagePopup from "./Components/MessagePopup";

export default function AccountComponent() {

    return (
        <Box marginTop={2}>

            <Container maxWidth="fixed">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ backgroundColor: 'white', height: "87vh", overflow: "auto", borderRadius: '5px', padding: '10px' }}>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AddIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Link to="/account/addSoftware"><ListItemText primary="Add Software" secondary="Add a new software product." /></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ConstructionIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Link to="/account/manageSoftware"><ListItemText primary="Manage Software" secondary="Modify/Delete existing Software Products" /></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Avatar />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Profile" secondary="Manage your profile" />
                                </ListItem>
                                
                            </List>
                        </div>
                    </Grid>
                </Grid>
                <MessagePopup type='Alert' message="This is an example message" status='success'/>
            </Container>
        </Box>
    );
}