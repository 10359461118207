import React, { useState, useEffect } from "react";
import axiosBaseURL from "../../../httpCommon";
import { Divider, FormControl, Stack, TextField, Tooltip } from "@mui/material";
import { Cancel, Done, PlaylistAdd } from "@mui/icons-material";

function IntegrationsComponent({
    key,
    integration,
    handleSave,
    handleDelete,
    handleChange,
}) {

    const [parents, setParents] = useState([
        { software_id: 0, name: "No Parent" },
    ]);
    const intType = [
        {
            id: 1,
            label: "Computer Aided Design",
        },
        {
            id: 2,
            label: "Computer Aided Machining",
        },
        {
            id: 3,
            label: "Quality Management Systems",
        },
        {
            id: 4,
            label: "ERP",
        },
        {
            id: 5,
            label: "Miscellaneous",
        },
        {
            id: 6,
            label: "Simulation",
        },
        {
            id: 7,
            label: "Design, Documentation",
        },
        {
            id: 8,
            label: "Quotation",
        },
    ];
    useEffect(() => {
        const fetchSoftwareData = async () => {
            try {
                await axiosBaseURL
                    .get("/apiv1/getSoftwareList/")
                    .then((response) => {
                        setParents([...parents, ...response.data.message]);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            } catch (error) {
                // Handle errors if the request fails
                console.error("Error:", error);
            }
        };
        fetchSoftwareData();
    }, []);
    return (
        <Stack spacing={2} direction={{ sm: "column", md: "row" }} marginBottom={2}>
            <TextField
                helperText="Product Name"
                name="integName"
                id="integName"
                variant="outlined"
                value={integration.product}
                onChange={(e) => handleChange("product", e.target.value)}
                sx={{ margin: "auto" }}
                SelectProps={{
                    native: true,
                }}
                select
                fullWidth
            >
                {parents.map((option) => (
                    <option key={option} value={option.software_id}>
                        {option.name}
                    </option>
                ))}
            </TextField>
            <FormControl
                id="select"
                fullWidth
                sx={{
                    margin: "auto",
                    marginTop: { xs: "15px", sm: "auto" },
                    marginBottom: { xs: "15px", sm: "auto" },
                }}
            >
                <TextField
                    helperText="Integration Type"
                    name="integType"
                    id="integType"
                    variant="outlined"
                    defaultValue={intType[0]}
                    fullWidth
                    value={integration.type}
                    onChange={(e) => handleChange("type", e.target.value)}
                    select
                    SelectProps={{
                        native: true,
                    }}
                >
                    {intType.map((option) => (
                        <option key={option.id} value={option.label}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
            </FormControl>
            <Tooltip title="Add new software" placement="bottom">
                <PlaylistAdd
                    // onClick={() => handleOpen()}
                    sx={{ fontSize: "30px", cursor: "pointer" }}
                />
            </Tooltip>
            <Stack direction="column">
                {!integration.isSaved && (
                    <>
                        <Tooltip title="Save" placement="right-end">
                            <Done className="doneIcon" onClick={handleSave} />
                        </Tooltip>
                        <Divider orientation="horizontal" flexItem sx={{ my: 0.5 }} />
                    </>
                )}
                <Tooltip title="Delete" placement="right-end">
                    <Cancel className="cancelIcon" onClick={() => handleDelete(key)} />
                </Tooltip>
            </Stack>
        </Stack>
    );
}

export default IntegrationsComponent;
