import React from "react";
import axiosBaseURL from "../../httpCommon";
import {
    AppBar,
    Toolbar,
    Box,
    Stack,
} from "@mui/material";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import SendIcon from "@mui/icons-material/Send";
// import SoftwareListSidebar from "./Components/SoftwareListSidebar";
import ChatResponseCard from "./Components/chatResponseCard";


export default function ChatHome() {
    const [Question, setQuestion] = React.useState("");
    const [Answers, setAnswer] = React.useState([]);
    const [PrevAnswers, setPrevAnswers] = React.useState([]);
    const [PrevQuestion, setPrevQuestions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isVoiceCommand, setIsVoiceCommand] = React.useState(false);

    const handleSubmit = (question) => {
        setIsLoading(true);
        axiosBaseURL
            .post("/apiv1/chat/", { q: question })
            .then((response) => {
                setPrevAnswers([...PrevAnswers, Answers]);
                setPrevQuestions([...PrevQuestion, question]);
                setQuestion("");
                setAnswer((PrevAnswers) => [...PrevAnswers, response.data.message]);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };

    const handleVoiceCommand = async () => {
        setIsVoiceCommand(true);


        try {
            const translation = await fetch(`https://api.openai.com/v1/audio/translations`, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer sk-proj-2cUbL0Bt7rxsDv2mV7VDkAz27Sm7D2E`,
                },
                body: JSON.stringify({
                    model: "whisper-1",
                    file: `url(${process.env.PUBLIC_URL}/static/media/audio/micro-machines.wav)`,
                }),
            });

            console.log(translation);
            setQuestion(translation);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = (index) => {
        const updatedAnswers = [...Answers];
        const updatedQuestions = [...PrevQuestion];
        updatedQuestions.splice(index, 1);
        setPrevQuestions(updatedQuestions);
        updatedAnswers.splice(index, 1);
        setAnswer(updatedAnswers);
    };



    // setQuestion("What is the best software for my business?");
    // setAnswer("Once upon a time in a quaint village, there was a curious cat named Whiskers. One sunny afternoon, Whiskers discovered a tiny, glittering key in the garden. Intrigued, he followed a trail of shimmering dust that led him to an old oak tree.");
    return (
        <Box sx={{ display: "flex" }}>

            {/* <SoftwareListSidebar /> */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1
                }}
            >
                <div
                    style={{
                        height: "72vh",
                        overflow: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "rgba(155, 155, 155, 0.7) transparent",
                        paddingLeft: "10%",
                        paddingRight: "10%",
                    }}
                >
                    <ChatResponseCard answers={Answers} questions={PrevQuestion} handleDelete={handleDelete} />
                </div>

                <AppBar
                    position="sticky"
                    style={{
                        paddingLeft: "10%",
                        paddingRight: "10%",
                        bottom: 0,
                        top: "auto",
                    }}
                    className="chatQuestionAppBar"
                >
                    <Toolbar variant="dense">
                        <Stack
                            direction={"row"}
                            spacing={2}
                            alignItems={"center"}
                            style={{
                                width: "100%",
                                padding: "5px",
                                border: "1px solid #E0E0E0",
                                borderRadius: "25px",
                                boxShadow: "0px 2px 4px #6C98AD",
                                backgroundColor: "white",
                            }}
                        >
                            <input
                                type="text"
                                value={Question}
                                onChange={(event) => setQuestion(event.target.value)}
                                style={{
                                    borderRadius: "25px",
                                    flex: 1,
                                    border: "none",
                                    outline: "none",
                                    fontSize: "15px",
                                    marginLeft: "5px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                                placeholder="Describe your problem here..."
                            />
                            {isLoading &&
                                <img src={`${process.env.PUBLIC_URL}/static/media/loader.webp`} alt="loader" height='20px' width='auto' />
                            }
                            {/* <AudioRecorder /> */}

                            <GraphicEqIcon
                                onClick={handleVoiceCommand}
                                className="chatQuestionRecordButton"
                            />
                            <div className="chatQuestionSendButton">
                                <SendIcon
                                    onClick={!isLoading || !Question ? () => handleSubmit(Question) : null}

                                    sx={{
                                        fontSize: 25,
                                        color: isLoading || !Question ? "grey" : "white",
                                        pointerEvents: isLoading ? "none" : "auto",
                                    }}
                                />
                            </div>
                        </Stack>
                    </Toolbar>
                </AppBar>
            </Box>
        </Box>
    );
}
