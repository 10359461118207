import React from "react";
import ChatHome from "./ChatAlloy/ChatHome";




export default function Home() {
    return (
        <>
            <ChatHome></ChatHome>
        </>
    );
}