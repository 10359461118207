import Axios from 'axios';
import config from './config.json';

const axiosBaseURL = Axios.create({
    //baseURL: config['BaseURL'] + ":" + config['Port'],
    baseURL:config['BaseURL'],
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
    }
});

export default axiosBaseURL;
