import React from 'react';
import '../../App.css';

const FooterComponent = () => {
    return (
        <footer className="App-footer">
            <p><a href='https://www.tailoredalloys.com/' style={{'text-direction': 'none'}}>Tailored Alloys</a>. All rights reserved.</p>
        </footer>
    );
};

export default FooterComponent;