import React from 'react';
import { Box, Button, Chip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function AddFieldButton({addFunction, label}) {
    // Add your component's logic here

    return (
        <Box
            display="flex"
            justifyContent="center"
            sx={{ mt: 2 }}
        >
            <Button
                variant="text"
                color="primary"
                type="button"
                onClick={addFunction}
            >
                <Chip icon={<AddCircleIcon />} label={label} />
            </Button>
        </Box>
    );
}

export default AddFieldButton;
