import { useState, useEffect } from "react";
import * as React from "react";
import {
    Skeleton,
    ListItemButton,
    Toolbar,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Drawer,
    CssBaseline,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosBaseURL from "../../httpCommon";
import HomeIcon from "@mui/icons-material/Home";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import AddIcon from "@mui/icons-material/Add";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CompareIcon from '@mui/icons-material/Compare';

const drawerWidth = 240;

export default function SoftwareListSidebar({
    mobileOpen,
    handleDrawerToggle,
    handleDrawerClose,
}) {
    const [category, setCategory] = React.useState("");
    const [softwareList, setSoftwareList] = useState([]);
    const navigate = useNavigate();

    const handleOpenSoftware = (id) => {
        navigate("/account/manageSoftware/swot/" + id);
    };

    const fetchSoftwareList = async () => {
        try {
            const response = await axiosBaseURL.get("/apiv1/getSoftwareList/");
            setSoftwareList(response.data.message);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (event) => {
        setCategory(event.target.value);
    };

    useEffect(() => {
        fetchSoftwareList();
    }, []);
    const menuItems = {
        Home: [<HomeIcon />, "/"],
        Product: [<Inventory2Icon />, "/account/manageSoftware"],
        "Add Software": [<AddIcon />, "/account/manageSoftware/addSoftware"],
        "Compare Software": [<CompareIcon />, "/account/compareSoftware"],
        Account: [<AccountCircleIcon />, "/account"],
    };

    const handleNavigate = async (url) => {
        try {
            window.location.href = url;
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const drawer = (
        <div>
            <Toolbar />
            <List>
                <FormControl sx={{ width: "93%", marginLeft: "5px" }} size="small">
                    <InputLabel id="demo-select-small-label">Select Category</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={category}
                        label="Category"
                        onChange={handleChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Software Products</MenuItem>
                        <MenuItem value={20}>Materials</MenuItem>
                        <MenuItem value={30}>Products</MenuItem>
                    </Select>
                </FormControl>
            </List>
            <Divider />
            <List>
                {softwareList && softwareList.length > 0
                    ? softwareList.map((software, index) => (
                        <ListItem
                            key={index}
                            disablePadding
                            onClick={() => handleOpenSoftware(software.software_id)}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <Avatar
                                        src={`${process.env.REACT_APP_PUBLIC_URL}/${software.logo}`}
                                        alt={`${software.name} logo`}
                                        sx={{ width: 30, height: "auto" }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={software.name} />
                            </ListItemButton>
                        </ListItem>
                    ))
                    : // Generate multiple skeletons to match the list items
                    Array.from(new Array(5)).map((_, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Skeleton variant="circular" width={30} height={30} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Skeleton variant="text" width={100} height={30} />
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
            </List>
            <Divider />
            <List>
                {Object.entries(menuItems).map(([key, value]) => (
                    <ListItem key={key} disablePadding>
                        <ListItemButton onClick={() => handleNavigate(value[1])}>
                            <ListItemIcon>{value[0]}</ListItemIcon>
                            <ListItemText primary={key} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <>
            <CssBaseline />
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
        </>
    );
}
