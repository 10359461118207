import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CheckCircle, Error, Close } from '@mui/icons-material';
import { Stack } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,

    bgcolor: 'background.paper',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
};

export default function AlertModal({ alertModal }) {
    const [open, setOpen] = React.useState(alertModal["open"]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {alertModal["status"] === "success" ? (
                            <CheckCircle color="success" sx={{fontSize:'100px'}} />
                        ) : (
                            <Error color="error" sx={{fontSize:'100px'}} />
                        )}

                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {alertModal["message"]}
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    );
}