import React, { useState } from 'react';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { auth } from "./firebase"
import { createUserWithEmailAndPassword } from 'firebase/auth';
import axiosBaseURL from '../../httpCommon';

export default function RegisterScreen() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [resError, setResError] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (event) => {
        event.preventDefault()

        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user
                const uid = userCredential.user.uid
                console.log(user)
                try {
                    // Make an Axios POST request to your API endpoint
                    const response = await axiosBaseURL.post('/apiv1/authenticate/', { 'uid': uid });
                    // Handle the response from the API
                    console.log('API response:', response.data);
                    localStorage.setItem('token', response.data.token);
                    window.location.href = '/';
                } catch (error) {
                    // Handle errors if the request fails
                    console.error('Error:', error);
                    // You can also display error messages to the user or handle them in any way you prefer
                }
            })
            .catch((error) => {
                console.log(error)
                setResError(true)
            })
    };

    const handleLogin = () => {
        navigate('/Login');
    }

    return (
        <>
            <Box
                maxWidth={{ xs: "500px", sm: "600px" }}
                mx={{ xs: "20px", sm: 'auto' }}
                my={4}
                display="flex"
                justifyContent={'center'}
                gap={4}
                p={2}
                sx={{ alignItems: "center", borderRadius: 1, boxShadow: 2, backgroundColor: '#ffffff' }}
            >
                <form>
                    <Stack
                        width={'100%'}
                        direction="column"
                        spacing={2}
                        paddingTop={'5%'}
                        paddingBottom={'5%'}
                    >
                        <img
                            src="https://images.squarespace-cdn.com/content/v1/629e359bdb19db2cef3c7f9f/bdfc77a8-04e5-4818-874d-bd6ab1f15b3f/TALOGOWEBSITE.jpg?format=1500w"
                            alt="logo"
                            style={{ width: '70%', height: 'auto', margin: '30px', alignContent: 'center', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                        <h3 style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', color: 'grey' }}>Register</h3>
                        {resError && <Alert severity="error">A user with this username already exists.</Alert>}
                        <TextField
                            id='email'
                            label="Email"
                            variant="filled"
                            inputProps={{ style: { backgroundColor: 'white', borderBlockColor: '#739C9A' } }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            id='password'
                            label="Password"
                            variant="filled"
                            type="password"
                            inputProps={{ style: { backgroundColor: 'white', borderBlockColor: '#739C9A' } }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Stack direction="row" spacing={2}>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={handleLogin}
                                sx={{ alignItems: "center" }}
                            >
                                Login
                            </Button>
                            <Button
                                type='submit'
                                variant="contained"
                                color="secondary"
                                sx={{ alignItems: "center" }}
                                onClick={onSubmit}
                            >
                                Register
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </>
    );
};