import React from "react";
import IosShareIcon from "@mui/icons-material/IosShare";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useNavigate } from "react-router-dom";
import axiosBaseURL from "../../httpCommon";

import { Stack, Toolbar, Tooltip } from "@mui/material";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const SWOTSubHeaderComponent = ({ contents, software_id }) => {
    const navigate = useNavigate();

    const handleExport = () => {
        const input = document.getElementById("software-overview");
        if (input) {
            const pdf = new jsPDF("p", "px", "a4"); // Letter size page of PDF
            const contentWidth = input.clientWidth;
            const contentHeight = input.clientHeight;
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            const marginLeft = 20; // Left margin
            const marginRight = 20; // Right margin
            const marginTop = 20; // Top margin

            const scale = (pageWidth - marginLeft - marginRight) / contentWidth; // Scale factor to fit content to page

            html2canvas(input, { scale: 2 }) // Increase resolution by scaling
                .then((canvas) => {
                    const imgData = canvas.toDataURL("image/png");
                    let pdfPageHeight = 0;
                    let y = 0;

                    const marginBottom = 20; // Bottom margin

                    while (y < contentHeight) {
                        pdf.addImage(
                            imgData,
                            "PNG",
                            marginLeft,
                            pdfPageHeight + marginTop,
                            contentWidth * scale,
                            pageHeight - marginTop - marginBottom
                        );
                        y += pageHeight - marginTop - marginBottom;
                        pdfPageHeight += pageHeight - marginTop - marginBottom;

                        // Add new page only if there's more content to add
                        if (y < contentHeight) {
                            pdf.addPage();
                        }
                    }

                    pdf.save("report.pdf");
                });
        }
    };

    const handleEdit = () => {
        navigate("/account/manageSoftware/addSoftware/" + software_id);
    };

    const handlePrint = () => {
        const input = document.getElementById("software-overview");
        if (input) {
            const content = input.innerHTML;
            const printWindow = window.open("", "_blank");
            printWindow.document.open();
            printWindow.document.write(`
                <html>
                    <head>
                        <title>Print SWOT</title>
                    </head>
                    <body>
                        <div id="software-overview" class="background-color">
                            ${content}
                        </div>
                    </body>
                </html>
            `);
            printWindow.document.close();
            printWindow.print();
            window.close();
        }
    };

    const handleLink = () => {
        console.log("Linking SWOT");
    };

    const handleDelete = async () => {
        console.log("Deleting SWOT");
        try {
            await axiosBaseURL.post(`/apiv1/delete_software/${software_id}/`)
                .then(response => {
                    console.log('API response:', response.data);
                    navigate("/account/manageSoftware/");
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } catch (error) {
            // Handle errors if the request fails
            console.error('Error:', error);

        }
    };

    const handleShare = () => {
        console.log("Sharing SWOT");
    };

    const buttonStyle = {
        color: "grey",
        borderRadius: "5px",
        marginLeft: "2px",
        marginRight: "2px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "blue",
        },
    };

    return (
        <div>
            <Stack
                direction="row"
                spacing="auto"
                style={{ marginTop: "auto", marginBottom: "auto" }}
            >
                <div>{/* Add Name */}</div>
                <div>
                    <Tooltip title="Edit" placement="bottom">
                        <EditNoteOutlinedIcon
                            sx={{ color: "grey" }}
                            onClick={handleEdit}
                            style={buttonStyle}
                        />
                    </Tooltip>
                    <Tooltip title="Delete" placement="bottom">
                        <DeleteForeverOutlinedIcon
                            sx={{ color: "grey" }}
                            onClick={handleDelete}
                            style={buttonStyle}
                        />
                    </Tooltip>
                    <Tooltip title="Print" placement="bottom">
                        <LocalPrintshopOutlinedIcon
                            sx={{ color: "grey" }}
                            onClick={handlePrint}
                            style={buttonStyle}
                        />
                    </Tooltip>
                    <Tooltip title="Export" placement="bottom">
                        <IosShareIcon
                            sx={{ color: "grey" }}
                            onClick={handleExport}
                            style={buttonStyle}
                        />
                    </Tooltip>
                    <Tooltip title="Link" placement="bottom">
                        <LinkOutlinedIcon
                            sx={{ color: "grey" }}
                            onClick={handleLink}
                            style={buttonStyle}
                        />
                    </Tooltip>
                    <Tooltip title="Share" placement="bottom">
                        <ShareOutlinedIcon
                            sx={{ color: "grey" }}
                            onClick={handleShare}
                            style={buttonStyle}
                        />
                    </Tooltip>
                </div>
            </Stack>
        </div>
    );
};

export default SWOTSubHeaderComponent;
