import React from "react";
import TextField from "@mui/material/TextField";
import Done from "@mui/icons-material/Done";
import Cancel from "@mui/icons-material/Cancel";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Tooltip } from "@mui/material";

function PricingComponent({
    key,
    pricing,
    handleSave,
    handleDelete,
    handleChange,
}) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    

    const users = Array.from({ length: 1000 }, (_, i) => ({
        value: i,
        label: i,
    }));
    const frequencies = ["Monthly", "Yearly", "Perpetual", "Token Based"];

    return (
        <Stack
            direction={isSmallScreen ? "column" : "row"}
            spacing={2}
            sx={{ marginBottom: 2 }}
        >
            <TextField
                required
                label="Title"
                id="title"
                value={pricing.title}
                onChange={(e) => handleChange("title", e.target.value)}
                // sx={{ minWidth: 200 }}
                size="small"
            />
            <TextField
                required
                label="Sign Up Fee"
                id="sign-up-fee"
                value={pricing.signUp}
                onChange={(e) => handleChange("signUp", e.target.value)}
                sx={{ minWidth: 100 }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                type="number"
                inputProps={{ min: 0 }}
            />
            <TextField
                required
                label="Subscription"
                id="subscription"
                sx={{ minWidth: 100 }}
                value={pricing.subscription}
                onChange={(e) => handleChange("subscription", e.target.value)}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                type="number"
                inputProps={{ min: 0 }}
            />
            <TextField
                helperText="Frequency"
                name="priceFrequency"
                id="frequency"
                variant="outlined"
                value={pricing.frequency}
                onChange={(e) => handleChange("frequency", e.target.value)}
                sx={{ minWidth: 150 }}
                select
                SelectProps={{
                    native: true,
                }}
            >
                <option value="" disabled>
                    Select
                </option>
                {frequencies.map((value, index) => (
                    <option key={index} value={value}>
                        {value}
                    </option>
                ))}
            </TextField>
            <TextField
                helperText="Users"
                name="users"
                id="users"
                variant="outlined"
                value={pricing.user}
                onChange={(e) => handleChange("user", e.target.value)}
                sx={{ minWidth: 100 }}
                select
                SelectProps={{
                    native: true,
                }}
            >
                <option value="" disabled>
                    Select
                </option>
                {users.map((option) => (
                    <option key={option.label} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </TextField>
            <Stack direction="column">
                {!pricing.isSaved && (
                    <>
                        <Tooltip title="Save" placement="right-end">
                            <Done className="doneIcon" onClick={handleSave} />
                        </Tooltip>
                        <Divider orientation="horizontal" flexItem sx={{ my: 0.5 }} />
                    </>
                )}
                <Tooltip title="Delete" placement="right-end">
                    <Cancel className="cancelIcon" onClick={() => handleDelete(key)} />
                </Tooltip>
            </Stack>
        </Stack>
    );
}

export default PricingComponent;
