import React, { useState } from 'react';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import axiosBaseURL from '../../httpCommon';
import { auth, googleProvider, facebookProvider, gitHubProvider } from './firebase'
import {
    FacebookAuthProvider,
    GithubAuthProvider,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup
} from 'firebase/auth';
import { Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import Alert from '@mui/material/Alert';

const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [resError, setResError] = useState(false);


    const navigate = useNavigate();

    const handleGoogleLogin = () => {
        signInWithPopup(auth, googleProvider)
            .then(async (result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result)
                const user = result.user
                const uid = user.uid
                try {
                    // Make an Axios POST request to your API endpoint
                    const response = await axiosBaseURL.post('/apiv1/authenticate/', { 'uid': uid });
                    // Handle the response from the API
                    console.log('API response:', response.data);
                    localStorage.setItem('token', response.data.token);
                    window.location.href = '/';
                } catch (error) {
                    // Handle errors if the request fails
                    console.error('Error:', error);
                    // You can also display error messages to the user or handle them in any way you prefer
                }
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                setResError(true)
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.log(errorMessage)
            })
    }

    const handleFacebookLogin = () => {
        signInWithPopup(auth, facebookProvider)
            .then(async (result) => {
                const credential = FacebookAuthProvider.credentialFromResult(result)
                const user = result.user
                const uid = user.uid
                console.log(uid)
                try {
                    // Make an Axios POST request to your API endpoint
                    const response = await axiosBaseURL.post('/apiv1/authenticate/', { 'uid': uid });
                    // Handle the response from the API
                    console.log('API response:', response.data);
                    localStorage.setItem('token', response.data.token);
                    window.location.href = '/';
                } catch (error) {
                    // Handle errors if the request fails
                    console.error('Error:', error);
                    // You can also display error messages to the user or handle them in any way you prefer
                }
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = FacebookAuthProvider.credentialFromError(error);
                setResError(true)
                console.log(errorMessage)
            })

    }

    const handleGitHubLogin = () => {
        signInWithPopup(auth, gitHubProvider)
            .then(async (result) => {
                const credential = GithubAuthProvider.credentialFromResult(result)
                const user = result.user
                const uid = user.uid
                console.log(uid)
                try {
                    // Make an Axios POST request to your API endpoint
                    const response = await axiosBaseURL.post('/apiv1/authenticate/', { 'uid': uid });
                    // Handle the response from the API
                    console.log('API response:', response.data);
                    localStorage.setItem('token', response.data.token);
                    window.location.href = '/';
                } catch (error) {
                    // Handle errors if the request fails
                    console.error('Error:', error);
                    // You can also display error messages to the user or handle them in any way you prefer
                }
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GithubAuthProvider.credentialFromError(error);
                setResError(true)
                console.log(errorMessage)
            })

    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = async () => {
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user
                const uid = userCredential.user.uid
                console.log(user)
                try {
                    // Make an Axios POST request to your API endpoint
                    const response = await axiosBaseURL.post('/apiv1/authenticate/', { 'uid': uid });
                    // Handle the response from the API
                    console.log('API response:', response.data);
                    localStorage.setItem('token', response.data.token);
                    window.location.href = '/';
                } catch (error) {
                    // Handle errors if the request fails
                    console.error('Error:', error);
                    // You can also display error messages to the user or handle them in any way you prefer
                }
            })
            .catch((error) => {
                const errorCode = error.code
                const errorMessage = error.message
                setResError(true)
            })
    }

    const handleRegister = () => {
        navigate('/register');
    }

    return (
        <>
            <Box
                maxWidth={{ xs: "500px", sm: "700px" }}
                mx={{ xs: "20px", sm: 'auto' }}
                my={4}
                display="flex"
                justifyContent={'center'}
                gap={4}
                p={2}
                sx={{ alignItems: "center", borderRadius: 1, boxShadow: 2, backgroundColor: '#ffffff' }}
            >
                <Stack
                    width={'100%'}
                    direction="column"
                    spacing={2}
                    paddingTop={'5%'}
                    paddingBottom={'5%'}
                >
                    <img
                        src="https://images.squarespace-cdn.com/content/v1/629e359bdb19db2cef3c7f9f/bdfc77a8-04e5-4818-874d-bd6ab1f15b3f/TALOGOWEBSITE.jpg?format=1500w"
                        alt="logo"
                        style={{ width: '70%', height: 'auto', margin: '30px', alignContent: 'center', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                    />
                    <h3 style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', color: 'grey' }}>Login</h3>
                    {resError && <Alert severity="error">Login Failed! Username or Password is incorrect!</Alert>}
                    <TextField
                        label="Email"
                        variant="filled"
                        value={email}
                        onChange={handleEmailChange}
                        inputProps={{ style: { backgroundColor: 'white', borderBlockColor: '#739C9A' } }}
                    />
                    <TextField
                        label="Password"
                        variant="filled"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        inputProps={{ style: { backgroundColor: 'white', borderBlockColor: '#739C9A' } }}
                    />
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleLogin}
                            sx={{ alignItems: "center" }}
                        >
                            Login
                        </Button>
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={handleRegister}
                            sx={{ alignItems: "center" }}
                        >
                            Register
                        </Button>
                    </Stack>
                    <Typography sx={{ textAlign: "center", fontSize: "15px" }}> or Sign in with</Typography>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <GoogleIcon onClick={handleGoogleLogin} sx={{ fontSize: 30, cursor: 'pointer' }} />
                        <FacebookIcon onClick={handleFacebookLogin} sx={{ fontSize: 30, cursor: 'pointer' }} />
                        <GitHubIcon onClick={handleGitHubLogin} sx={{ fontSize: 30, cursor: 'pointer' }} />
                    </Stack>
                    {/* <Button
                        startIcon={<GoogleIcon />}
                        fullWidth
                        onClick={handleGoogleLogin}
                        sx={{
                            margin: "auto",
                            backgroundColor: "#4285F4",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "15px",
                            ":hover": { backgroundColor: "white", color: "#4285F4" }
                        }}>
                        Sign in with Google
                    </Button>
                    <Button
                        startIcon={<FacebookIcon />}
                        fullWidth
                        onClick={handleFacebookLogin}
                        sx={{
                            margin: "auto",
                            backgroundColor: "#1877F2",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "15px",
                            ":hover": { backgroundColor: "white", color: "#1877F2" }
                        }}
                    >Sign in with Facebook
                    </Button>
                    <Button
                        startIcon={<GitHubIcon />}
                        fullWidth
                        onClick={handleGitHubLogin}
                        sx={{
                            margin: "auto",
                            backgroundColor: "black",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "15px",
                            ":hover": { backgroundColor: "white", color: "black" }
                        }}>
                        Sign in with GitHub
                    </Button> */}
                </Stack>
            </Box >
        </>
    );
};

export default LoginScreen;