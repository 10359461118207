import { React, useState, useEffect } from 'react';
import { Box, Container, Grid, Stack, Card, CardContent } from '@mui/material';
import SWOTItemComponent from './Components/SWOTItemComponent';
import axiosBaseURL from '../httpCommon';
import Skeleton from '@mui/material/Skeleton';

const ManageSoftware = () => {
    // Your code here
    const [softwareOverviewList, setSoftwareOverviewList] = useState([]);

    const fetchSoftwareOverviews = async () => {
        try {
            const response = await axiosBaseURL.get('/apiv1/all_sw_lst/');
            setSoftwareOverviewList(response.data.message);
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        fetchSoftwareOverviews();
    }, []);

    return (
        <Box marginTop={2}>
            <Container maxWidth="fixed">
                <div style={{ backgroundColor: 'white', minHeight: "87vh", borderRadius: '5px', padding: '20px' }}>
                    
                            {softwareOverviewList.length > 0 ? (
                                <Stack spacing={3} direction='column'>
                                    {softwareOverviewList.map((software, index) => {
                                        console.log(software);
                                        return <SWOTItemComponent key={index} softwareList={software} />;
                                    })}
                                </Stack>
                            ) : (<Card sx={{ display: 'flex' }} >
                                <Skeleton variant="rectangular" width={150} height={150} sx={{ margin: 1 }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Skeleton variant="text" width={200} height={30} sx={{ marginBottom: 1 }} />
                                        <Skeleton variant="text" width={300} height={100} />
                                    </CardContent>
                                </Box>
                            </Card>
                            )}
                </div>
            </Container>
        </Box>
    );
};

export default ManageSoftware;